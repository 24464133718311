// Custom JS
var n = jQuery.noConflict();
function isotopAutoSet() {
    "use strict";
   // Blog      
    jQuery(".blog-carousel").each(function() {
        "use strict";
        if (n(this).attr("id")) {
            var e = n(this).attr("id").replace("_blog_carousel", "");
            n(".blog-carousel").addClass("owl-carousel");
            n(".blog-carousel").owlCarousel({
                navigation: true,
                pagination: false,
                items: e,
                itemsLarge: [1400, e],
                itemsDesktop: [1200, e],
                itemsDesktopSmall: [979, 2],
                itemsTablet: [600, 2],
                itemsMobile: [479, 1]
            })
        }
    });
}
jQuery(window).on('load',function() {  "use strict";  isotopAutoSet()});
jQuery(window).resize(function() { "use strict"; isotopAutoSet()});


function testimonialSlider() {
    "use strict";
    // Testimonials
    jQuery(".testimonial-carousel").each(function() {
        "use strict";
            n(".testimonial-carousel .elementor-widget-wrap").addClass("owl-carousel");
            n(".testimonial-carousel .elementor-widget-wrap").owlCarousel({
                navigation: false,
                pagination: false,
                autoplay:1000,
                items: 2,
                itemsLarge: [1400, 1],
                itemsDesktop: [1200, 1],
                itemsDesktopSmall: [979, 1],
                itemsTablet: [600, 1],
                itemsMobile: [479, 1]
            })
    });	
}
    jQuery(window).on('load',function() {  "use strict";  testimonialSlider()});
    jQuery(window).resize(function() { "use strict"; testimonialSlider()});
    jQuery(document).ready(function() { "use strict";  testimonialSlider() });

jQuery(window).on('load', function() {
    "use strict";
    var $height = 0 ;
    jQuery("body.single-trip").find(".site-footer").each(function(i){ 
		jQuery(this).insertAfter('.trip-content-area');
    });
    jQuery("body.single-trip .trip-content-area").find(".entry-header").each(function(i){ 
		jQuery(this).insertBefore('.trip-content-area .row');
	});    
    jQuery(".category-main-wrap").removeClass("category-list").addClass("category-grid");

});

// JS toggle for sidebar and footer
function SidebarFooterToggle() {
    "use strict";
    jQuery('footer .widget h3,footer .widget .widget-title ,footer .woocommerce-shipping-calculator .shipping-calculator-button').parent().addClass('toggled-off');
    jQuery('footer .widget h3,footer .widget .widget-title ,footer .woocommerce-shipping-calculator .shipping-calculator-button').on("click", function() {
        if (jQuery(this).parent().hasClass('toggled-on')) {
            jQuery(this).parent().removeClass('toggled-on');
            jQuery(this).parent().addClass('toggled-off');
        } else {
            jQuery(this).parent().addClass('toggled-on');
            jQuery(this).parent().removeClass('toggled-off');
        }
        return (false);
    });
}
jQuery(document).ready(function() {
    "use strict";
    SidebarFooterToggle()
});

jQuery(document).ready(function(){
    jQuery("#enquiry_submit_button").click(function(){
        jQuery('#enquiry_country').attr('required', false);
        jQuery('#enquiry_adult').attr('required', false);
        jQuery('#enquiry_children').attr('required', false);
        jQuery('#enquiry_subject').attr('required', false);   
        jQuery('#enquiry_message').attr('required', false); 
  });
});